import axios from "axios";
import qs from "qs";
import store from "@/store";
import { Notification, Loading } from "element-ui";
import {
  TIME_OUT_TIME,
  NOTIFY_STAY_TIME,
  CODE_UNAUTHORIZED,
  HEADER_TOKEN_NAME,
  STATUS_CODE_OK,
} from "@/config";
import router from "@/router";
let loadingInstace = null;
const fetch = axios.create({
  baseURL: process.env.VUE_APP_BACK_END_API_PX_URL,
  withCredentials: true,
  timeout: TIME_OUT_TIME,
});
// request拦截器
fetch.interceptors.request.use(
  (config) => {
    loadingInstace = Loading.service({
      lock: true,
      text: "加载中",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    if (store.getters.token) {
      config.headers[HEADER_TOKEN_NAME] = store.getters.token;
    }
    if (config.method === "post") {
      config.data = qs.stringify(config.data);
    }
    return config;
  },
  (error) => {
    loadingInstace && loadingInstace.close();
    Notification.error({
      title: "错误",
      message: error.message,
      duration: NOTIFY_STAY_TIME,
    });
    return Promise.reject(error);
  }
);
// respone拦截器
fetch.interceptors.response.use(
  (res) => {
    loadingInstace && loadingInstace.close();
    if (res.data.code && res.data.code !== STATUS_CODE_OK) {
      Notification.error({
        title: "错误",
        message: res.data.data,
        duration: NOTIFY_STAY_TIME,
      });
    }
    return res;
  },
  (err) => {
    loadingInstace && loadingInstace.close();
    if (err.response) {
      if (err.response.status === CODE_UNAUTHORIZED) {
        Notification.error({
          title: "错误",
          message: "登录超时，请重新登录进行验证",
          duration: NOTIFY_STAY_TIME,
        });
        router.push("/");
      } else {
        Notification.error({
          title: "错误",
          message: err.message,
          duration: NOTIFY_STAY_TIME,
        });
      }
    } else if (err.request) {
      if (err.request.status === CODE_UNAUTHORIZED) {
        Notification.error({
          title: "错误",
          message: "登录超时，请重新登录进行验证",
          duration: NOTIFY_STAY_TIME,
        });
        router.push("/");
      } else {
        Notification.error({
          title: "错误",
          message: "请求超时，请检查网络",
          duration: NOTIFY_STAY_TIME,
        });
        router.push("/");
      }
    } else {
      Notification.error({
        title: "错误",
        message: err.message,
        duration: NOTIFY_STAY_TIME,
      });
    }
    return Promise.reject(err);
  }
);
export default fetch;
