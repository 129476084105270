import fetch from "@/fetch";
// 获取查询条件列表
export const filterGet = () => {
  return fetch({
    method: "post",
    url: "/api/pc/index/listfilter",
  });
};

// 所有园区列表获取
export const allParkGet = () => {
  return fetch({
    method: "post",
    url: "/api/pc/index/loadallindexpark",
  });
};

// 根据过滤条件获取园区列表
export const filterParkGet = (params) => {
  return fetch({
    method: "post",
    url: "/api/pc/index/filterindexpark",
    data: params,
  });
};

// 园区详情获取
export const parkDetailGet = (params) => {
  return fetch({
    method: "post",
    url: "/api/pc/index/pcparkinfo",
    data: params,
  });
};

// 园区产业链企业名片
export const inchCompanyCardGet = (params) => {
  return fetch({
    method: "post",
    url: "/api/pc/index/pcparkinchcompanycard",
    data: params,
  });
};

// 获取楼宇列表
export const streetListGet = (params) => {
  return fetch({
    method: "post",
    url: "/api/pc/index/getstreetlist",
    data: params,
  });
};

// 根据地址获取经纬度
export const gpsInfoGetByAddress = (params) => {
  return fetch({
    method: "post",
    url: "/api/common/getgpsinfo",
    data: params,
  });
};

// 获取地图初始化参数
export const initParamsGet = (params) => {
  return fetch({
    method: "post",
    url: "/api/pc/index/initparams",
    data: params,
  });
};

// 获取网点类型列表
export const parkTypeListGet = (params) => {
  return fetch({
    method: "post",
    url: "/api/pc/index/loadindexparktype",
    data: params,
  });
};
